<template>
  <div>
    <b-card>
      <div class="d-flex">
        <p class="text-secondary my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span style="font-size: 18px" class="font-weight-light">
            Fiche processus métier :
          </span>
          <span style="font-size: 22px">{{ PROCESSUS_METIER.name }}</span>
        </p>

        <div class="row mb-3">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p
            class="h4 text-secondary my-auto mr-auto"
            style="margin: 6.656px 0 !important"
          >
            Informations générales
          </p>
          <a
            v-if="(isAdmin || isSuper || isArchitect) && !editMode"
            class="text-secondary"
            style="
              font-size: 30px;
              color: #007bff !important;
              cursor: pointer !important;
            "
            @click.prevent="editMode = !editMode"
            ><i class="mdi mdi-file-document-edit"></i
          ></a>
          <div
            v-else-if="isAdmin || isSuper || isArchitect"
            class="m-0 d-flex p-0"
          >
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="editMode = !editMode"
              pill
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="bg-secondary mb-3 mt-1" />
        </div>
        <div class="row" v-if="editMode">
          <!-- First Col -->
          <div class="col-md-6 col-12">
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Libellé processus:</label>
              </div>
              <div class="col-md-8">
                <b-form-input
                  type="text"
                  placeholder="Libellé"
                  :class="{
                    'is-invalid':
                      $v.newProcessus.name.$error &&
                      $v.newProcessus.name.$dirty,
                  }"
                  v-model="newProcessus.name"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newProcessus.name.$dirty"
                >
                  {{
                    !$v.newProcessus.name.required
                      ? "Champ obligatoire"
                      : !$v.newProcessus.name.minLength
                      ? `Le champ doit contenir au moins ${$v.newProcessus.name.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
                <!--  -->
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Description:</label>
              </div>
              <div class="col-md-8">
                <b-form-textarea
                  type="text"
                  placeholder="Description"
                  v-model="newProcessus.description"
                ></b-form-textarea>
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Patrimoine:</label>
              </div>
              <div class="col-md-8" id="add-page">
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newProcessus.patrimoine.$error &&
                      $v.newProcessus.patrimoine.$dirty,
                  }"
                >
                  <v-select
                    v-model="newProcessus.patrimoine"
                    placeholder="Patrimoine"
                    label="text"
                    :reduce="({ value }) => value"
                    :options="patrimoinesList"
                  />
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newProcessus.patrimoine.$dirty"
                >
                  {{
                    !$v.newProcessus.patrimoine.required
                      ? "Champ obligatoire"
                      : ""
                  }}
                </span>
                <!--  -->
              </div>
            </div>
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Capacité métier:</label>
              </div>

              <div class="col-md-8">
                <!-- <b-form-select placeholder="Capacité métier" /> -->
                <v-select
                  label="name"
                  v-model="newProcessus.capaciteMetiers"
                  :options="capaciteRatechements"
                  placeholder="Capacité métier"
                ></v-select>
              </div>
            </div>
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Processus métier:</label>
              </div>
              <div class="col-md-8">
                <v-select
                  label="name"
                  v-model="newProcessus.parent"
                  :options="processusRatechements"
                  :disabled="newProcessus.sousprocessus.length ? true : false"
                  placeholder="Processus Métier"
                  @input="
                    () => {
                      newProcessus.niveau = newProcessus.parent ? 2 : 1;
                    }
                  "
                ></v-select>
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Business Owner:</label>
              </div>
              <div class="col-md-8" id="add-page">
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newProcessus.businessOwner.$error &&
                      $v.newProcessus.businessOwner.$dirty,
                  }"
                >
                  <v-select
                    label="text"
                    :reduce="(user) => user.value"
                    :options="ownersList"
                    placeholder="Business Owner"
                    v-model="newProcessus.businessOwner"
                  />
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newProcessus.businessOwner.$dirty"
                >
                  {{
                    !$v.newProcessus.businessOwner.required
                      ? "Champ obligatoire"
                      : ""
                  }}
                </span>
                <!--  -->
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold"
                  >Organisation responsable:</label
                >
              </div>
              <div class="col-md-8" id="add-page">
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newProcessus.organisationResp.$error &&
                      $v.newProcessus.organisationResp.$dirty,
                  }"
                >
                  <v-select
                    label="text"
                    :reduce="(organisation) => organisation.value"
                    :options="organisationsList"
                    placeholder="Organisation responsable"
                    v-model="newProcessus.organisationResp"
                  />
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newProcessus.organisationResp.$dirty"
                >
                  {{
                    !$v.newProcessus.organisationResp.required
                      ? "Champ obligatoire"
                      : ""
                  }}
                </span>
                <!--  -->
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Niveau:</label>
              </div>
              <div class="col-md-8">
                <b-form-input
                  label="number"
                  placeholder="Niveau"
                  min="1"
                  disabled
                  v-model="newProcessus.niveau"
                />
              </div>
            </div>
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="mb-0 font-weight-bold"
                  >Taux de remplissage:</label
                >
              </div>
              <div class="col-md-8">
                <b-progress class="" max="100" show-progress height="15px">
                  <b-progress-bar
                    :value="tauxRemplissage"
                    :label="`${tauxRemplissage}%`"
                    :style="`background: ${
                      tauxRemplissage > 20 && tauxRemplissage <= 70
                        ? '#f5f245'
                        : tauxRemplissage > 70
                        ? '#23d92c'
                        : '#f5ba45'
                    }`"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12 col-md-6">
            <div class="my-4">
              <span>Libellé : </span>
              <span class="font-weight-bold">
                {{ PROCESSUS_METIER.name }}
              </span>
            </div>
            <div class="my-4">
              <span>Description : </span>
              <span class="font-weight-bold">
                {{ PROCESSUS_METIER.description }}
              </span>
            </div>

            <div class="my-4">
              <span>Patrimoine : </span>
              <span class="font-weight-bold">
                {{
                  PROCESSUS_METIER.patrimoine
                    ? PROCESSUS_METIER.patrimoine.name
                    : "-"
                }}
              </span>
            </div>

            <div class="my-4">
              <span>Capacité de rattachement : </span>
              <span
                v-if="PROCESSUS_METIER.capaciteMetiers"
                class="font-weight-bold"
              >
                {{ PROCESSUS_METIER.capaciteMetiers.name }}
              </span>
            </div>
            <div class="my-4">
              <span>Processus de rattachement : </span>
              <span class="font-weight-bold">
                {{
                  PROCESSUS_METIER.parent ? PROCESSUS_METIER.parent.name : "-"
                }}
              </span>
            </div>

            <div class="my-4">
              <span class="my-auto">Business Owner : </span>
              <span
                v-if="PROCESSUS_METIER.businessOwner"
                class="font-weight-bold"
              >
                {{ PROCESSUS_METIER.businessOwner.firstname }}
                {{ PROCESSUS_METIER.businessOwner.lastname }}
              </span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="my-4">
              <span>Oganisation responsable : </span>
              <span
                v-if="PROCESSUS_METIER.organisationResp"
                class="font-weight-bold"
              >
                {{ PROCESSUS_METIER.organisationResp.libelle }}
              </span>
            </div>
            <div class="my-4">
              <span>Niveau : </span>
              <span v-if="PROCESSUS_METIER.niveau" class="font-weight-bold">
                {{ PROCESSUS_METIER.niveau }}
              </span>
            </div>

            <div class="my-4 d-flex">
              <span class="col-md-4 p-0 my-auto">Taux de remplissage : </span>

              <div class="col-md-7 p-0 my-auto">
                <b-progress class="" max="100" show-progress height="15px">
                  <b-progress-bar
                    :value="tauxRemplissage"
                    :label="`${tauxRemplissage}%`"
                    :style="`background: ${
                      tauxRemplissage > 20 && tauxRemplissage <= 70
                        ? '#f5f245'
                        : tauxRemplissage > 70
                        ? '#23d92c'
                        : '#f5ba45'
                    }`"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />

      <div class="mt-3">
        <b-tabs
          :value="$store.state.tabs.processusTab"
          @changed="processusTab = $store.state.tabs.processusTab"
          @activate-tab="
            (newTabIndex) =>
              $store.dispatch('tabs/changeProcessusTab', newTabIndex)
          "
          class="tab-solid tab-solid-primary mt-3"
        >
          <b-tab title="Processus rattachés">
            <p class="text-secondary font-weight-bold">
              List des sous capacités
            </p>
            <div class="col-12 p-0">
              <hr class="mt-1 mb-3 bg-secondary" />
            </div>

            <job-process-processes
              :processes="PROCESSUS_METIER.sousprocessus"
            />
          </b-tab>

          <b-tab title="Applications">
            <job-process-application
              :applications="applications"
              @reload="reload"
            />
          </b-tab>

          <b-tab title="Objets manipulés">
            <job-process-business-object :object="[]" />
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators"; //validation
// import VueSlideBar from "vue-slide-bar";
import JobProcessApplication from "./components/JobProcessApplication";
import { USER_URL } from "@/helpers/constURL";
import JobProcessBusinessObject from "./components/JobProcessBusinessObject.vue";
import JobProcessProcesses from "./components/JobProcessProcesses.vue";
import Swal from "sweetalert2";
export default {
  components: {
    ModalActions,
    // VueSlideBar,
    JobProcessApplication,
    JobProcessBusinessObject,
    JobProcessProcesses,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    newProcessus: {
      name: "",
      description: "",
      patrimoine: "",
      capaciteMetiers: [],
      businessOwner: "",
      taux: 0,
      organisationResp: "",
      sousprocessus: [],
      applications: [],
      niveau: 1,
      parent: "",
    },
  }),
  validations: {
    newProcessus: {
      name: {
        required,
        minLength: minLength(5),
      },
      patrimoine: {
        required,
      },
      businessOwner: {
        required,
      },
      organisationResp: {
        required,
      },
      niveau: {
        required,
      },
    },
  },
  methods: {
    reload() {
      this.$store
        .dispatch("processusMetier/fetchProcessusMetier", this.$route.params.id)
        .then(() => {
          this.$store.dispatch(
            "processusMetier/fetchApplication",
            this.$route.params.id
          );
        });
    },
    updateItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$store
          .dispatch("processusMetier/updateProcessusMetier", {
            id: this.$route.params.id,
            ...this.newProcessus,
            niveau: parseInt(this.newProcessus.niveau),
            name: this.newProcessus.name,
            description: this.newProcessus.description,
            capaciteMetiers: this.newProcessus.capaciteMetiers
              ? this.newProcessus.capaciteMetiers["@id"]
              : null,
            sousprocessus: this.newProcessus.sousprocessus.map(
              (processus) => processus["@id"]
            ),
            organisationResp: this.newProcessus.organisationResp["@id"],
            applications: this.newProcessus.applications.map(
              (application) => application["@id"]
            ),
            parent: this.newProcessus.parent
              ? this.newProcessus.parent["@id"]
              : null, // Processus parent
          })
          .then(() => {
            this.$store
              .dispatch(
                "processusMetier/fetchProcessusMetier",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Le processus est mis à jour !", "", "success");
                this.editMode = false;
              });
          });
      }
    },
  },
  created() {
    this.$store
      .dispatch("processusMetier/fetchProcessusMetier", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
    this.$store.dispatch("processusMetier/fetchAllProcessusMetiers");
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store
      .dispatch(
        "processusMetier/fetchAllProcessusMetierApplications",
        this.$route.params.id
      )
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("capaciteMetier", ["CAPACITE_METIERS"]),
    ...mapGetters("processusMetier", ["PROCESSUS_METIER", "PROCESSUS_METIERS"]),
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("processusMetier", ["APPLICATIONS"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),

    processusRatechements() {
      return this.newProcessus.capaciteMetiers
        ? this.PROCESSUS_METIERS.filter(
            (process) =>
              process.id != this.$route.params.id && process.niveau === 1
          ).filter((processus) =>
            processus.capaciteMetiers
              ? processus.capaciteMetiers.id ===
                this.newProcessus.capaciteMetiers.id
              : false
          )
        : [];
    },
    capaciteRatechements() {
      return this.CAPACITE_METIERS;
    },
    applicationsList() {
      return this.APPLICATIONS;
    },
    patrimoinesList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        text: patrimoine.name,
        value: patrimoine["@id"],
      }));
    },
    ownersList() {
      return this.RESPONSABLES.map((responsable) => ({
        text: `${responsable.firstname} ${responsable.lastname}`,
        value: `/api/${USER_URL}/${responsable.id}`,
      }));
    },
    organisationsList() {
      return this.ORGANISATIONS.map((organisation) => ({
        text: organisation.libelle,
        value: organisation["@id"],
      }));
    },
    applications() {
      return this.APPLICATIONS.applications;
    },
    tauxRemplissage() {
      let i = 1;
      if (this.newProcessus.name) i++;
      if (this.newProcessus.description) i++;
      if (this.newProcessus.patrimoine) i++;
      if (this.newProcessus.capaciteMetiers) i++;
      if (this.newProcessus.parent) i++;
      if (this.newProcessus.businessOwner) i++;
      if (this.newProcessus.organisationResp) i++;
      if (this.newProcessus.applications.length) i++;
      if (this.newProcessus.sousprocessus.length) i++;
      if (this.newProcessus.objets && this.newProcessus.objets.length) i++;

      return ((i * 100) / 11).toFixed(2);
    },
  },
  watch: {
    PROCESSUS_METIER: function () {
      this.newProcessus = {
        ...this.newProcessus,
        name: this.PROCESSUS_METIER.name,
        description: this.PROCESSUS_METIER.description,
        taux: this.PROCESSUS_METIER.taux,
        businessOwner: this.PROCESSUS_METIER.businessOwner["@id"],
        patrimoine: this.PROCESSUS_METIER.patrimoine["@id"],
        organisationResp: this.PROCESSUS_METIER.organisationResp["@id"],
        capaciteMetiers: this.PROCESSUS_METIER.capaciteMetiers,
        sousprocessus: this.PROCESSUS_METIER.sousprocessus,
        applications: this.PROCESSUS_METIER.applications,
        parent: this.PROCESSUS_METIER.parent,
        objets: this.PROCESSUS_METIER.objets,
      };
    },
  },
};
</script>

<style></style>
